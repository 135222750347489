<template>
  <Line  :data="getData()" />
</template>

<script>
import { Line } from 'vue-chartjs'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js'


ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
)
export default {
  name: 'BarChart',
  props: ['valor'],
  components: { Line },
  data() {
    return {
      chartData: null,
      loaded: false,
}
  },
  methods: {
    getData(){
        console.log("desenhou")
        let chartData = {}
        chartData['labels'] = []
        chartData['datasets'] = []
        chartData['datasets'].push({
            label: 'M²',
            backgroundColor: '#f87979',
            data: []
        })

        // let valor_rev = this.valor
        // valor_rev= valor_rev.reverse()
        for(const index in this.valor){
            chartData['labels'].push(this.valor[index]["data"])
            chartData['datasets'][0]['data'].push(this.valor[index]["sum"])
        }

        return chartData
      }
    }

}

</script>