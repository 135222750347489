<template >
  <v-container>
    <v-row>
      <v-col cols="12" sm="8">
        <v-table density="compact">
          <thead>
          <tr>
            <th class="text-right">Data</th>
            <th class="text-right">M²</th>
            <th class="text-right">M² Filter</th>
            <th class="text-right">M² diff</th>
          </tr>
          </thead>
          <tbody>
          <tr
              v-for="item in count"
              :key="item"
          >
            <td class="text-right" > {{ item.data }}</td>
            <td class="text-right">{{ item.sum }}</td>
            <td class="text-right">{{ item.sum2 }}</td>
            <td class="text-right" v-bind:bgcolor="item.dataError">{{ item.diff }}</td>
          </tr>
          </tbody>

        </v-table>
      </v-col>

    </v-row>

  </v-container>
</template>

<script>
export default {
  data () {
    return {
      count: [],
    }
  },

  methods: {
    async getData(){
      try {
        let response = await fetch("/api/countByDate?type=1");
        let rawData = await response.json();
        console.log(rawData)

        let count = []
        for(const item in rawData){
          let sum = rawData[item].mean.toFixed(2)
          let sum2 = rawData[item].medianFiltered2.toFixed(2)
          // if (sum < 0.1 &&  sum2 < 0.1)
          //   continue

          // let diff = 0
          // if (sum2 > 1)
          //   diff = (100* (sum - sum2)/sum2).toFixed(2)

          let diff = (sum - sum2).toFixed(2)
          let dataError = diff > 4 ? "Red" : "ffffff"
          let data = {data: item, sum, sum2, diff, dataError}
          count.push(data)
        }
        this.count = count.reverse()
      }
      catch (e) {
        console.log(e);

      }
    }
  },
  created() {
    this.getData();
    console.log(this.$route.params.debug,this.$route)
    this.debugMode = this.$route.params.debug
  },


}
</script>
