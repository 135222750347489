<template >
  
  <v-container>

    <v-row>
      <div class="text-center">
      <v-btn @click=" this.getData('dca6326b9052')">Linha UV2-A (dca6326b9052)</v-btn>
      <v-btn @click=" this.getData('4851cf606ec8')">Linha UV1 (4851cf606ec8)</v-btn>
    </div>
    </v-row>
    <v-row>
      <h1>Metragem Atual de Linha UV2-A: {{ currentCount["dca6326b9052"]}} m^2</h1><br>


    </v-row>
    <v-row>

<h3>ultima Atualizacao: {{ currentCountAtt["dca6326b9052"]}}</h3>
</v-row>
    <v-row>
        <h1>Metragem Atual de Linha UV1: {{ currentCount["4851cf606ec8"] }} m^2</h1>
    </v-row>
    <v-row>

<h3>ultima Atualizacao: {{ currentCountAtt["4851cf606ec8"]}}</h3>
</v-row>
    <v-row>
      <v-col cols="12" sm="8">
        <BarChart :valor="count"/>

        <v-table density="compact">
          <thead>
          <tr>
            <th class="text-right"><div align="right">Data</div></th>
            <th class="text-right"><div align="right">M²</div></th>
          </tr>
          </thead>
          <tbody>
          <tr
              v-for="item in count"
              :key="item"
          >
            <td><div align="right">{{ item.data }}</div></td>
            <td><div align="right">{{ item.sum }}</div></td>
          </tr>
          </tbody>

        </v-table>

      </v-col>

    </v-row>


  </v-container>

</template>


<script>

import BarChart from '../components/BarChart.vue'

export default {
  name: 'homePage',
  components: { BarChart },

  data () {
    return {
      count: [],
      currentCount: {},
      currentCountAtt: {}
    }
  },

  methods: {
    async getCurrentData(mac){
      try {
        let response = await fetch("/api/csvRealTime?partition=" + mac);
        let currentData = await response.json();
        console.log(currentData)
        this.currentCount[mac] = Object.values(currentData)[0]["medianFiltered2"].toFixed(2);      
        this.currentCountAtt[mac] = Object.values(currentData)[0]["lastRegister"];      
      }
      catch (e) {
        console.log(e);

      }
    },
    async getData(mac){
      try {
        console.log(this.$route.params.camera, mac)
        let response = await fetch("/api/countByDate?partition=" +mac);
        let rawData = await response.json();
        console.log(rawData)
        let maxDate = new Date('2050-01-01')
        let count = []
        for(const item in rawData){
          let dateString = new Date(item)
          if (maxDate < dateString) {
            console.error("Data com bug:", item, dateString)
            continue
          }
          dateString.setDate(dateString.getDate()+1)
          dateString = dateString.toLocaleDateString()
          // console.log(dateString)
          // console.log(rawData[item])
          if (rawData[item].medianFiltered2 == null){
            console.error("valor nulo:", rawData[item].medianFiltered2)
            continue
          }
          let sum = rawData[item].medianFiltered2.toFixed(2)
          let data = {data: dateString, sum}
          count.push(data)
        }
        this.count = count
      }
      catch (e) {
        console.log(e);

      }
      //this.getCurrentData()

      //this.currentTimeout = setInterval(this.getCurrentData, 20000);
    }   
  },
  created() {
    // this.getData();
    this.getCurrentData("dca6326b9052")
    this.getCurrentData("4851cf606ec8")



  },
  updated() {
    // this.getData();

  },
  
  beforeRouteUpdate(to, from, next) {
    // Call the API query method when the URL changes
    // this.count = this.getData()

    next()
  }


}
</script>
