import { createApp } from 'vue'
import App from './App.vue'
import homePage from './pages/homePage.vue'
import debugPage from './pages/debugPage.vue'
import { createVuetify } from 'vuetify'
import { createRouter, createWebHashHistory } from 'vue-router'

import { loadFonts } from './plugins/webfontloader'

// 2. Define some routes
// Each route should map to a component.
// We'll talk about nested routes later.
const routes = [
]

const router = createRouter({
    // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
    history: createWebHashHistory(),
    routes, // short for `routes: routes`
})

// router.addRoute({ path: '/about', component: AppScript })
router.addRoute({ path: '/', component: homePage, props: true })
router.addRoute({ path: '/debug', component: debugPage })



loadFonts()
const vuetify = createVuetify() // Replaces new Vuetify()


createApp(App)
    .use(vuetify)
    .use(router)
    .mount('#app')
